




























































































































































































































































































































































































import AuthService from "../services/AuthService";
import LocationService from "../services/LocationService";
import moment from "moment";
import "moment/locale/de";
import { Component, Vue, Watch } from "vue-property-decorator";
import router from "../router/index";

interface User {
  username: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  email: string;
  roles: string[];
}

@Component
export default class Event extends Vue {
  private users: any = {};
  private user: User = <User>{};
  private role: string = "";
  private mode: string = "list";
  private deleteUser: any = null;
  private searchtext: string = "";
  private rolesearch: string = "";
  private currentPage: number = 1;
  private pagesize: number = 10;
  private sortBy = "lastName,firstName";
  private sortDesc = false;
  private sortDirection = "asc";
  private locations: any[] = [];
  private roles: any[] = [
    { value: "ROLE_ADMIN", text: "Administrator" },
    { value: "ROLE_OFFICE", text: "Pfarrbüro" },
    { value: "ROLE_STAFF", text: "Mitarbeiter" },
    { value: "ROLE_USER", text: "Benutzer" },
  ];
  private services: any[] = [];

  sortChanged(data: any) {
    this.sortBy = data.sortBy;
    this.sortDesc = data.sortDesc;
    this.sortDirection = data.sortDesc ? "desc" : "asc";
    this.reload();
  }

  handlePageSizeChange() {
    this.currentPage = 1;
    this.reload();
  }

  handlePageChange(page: number) {
    this.currentPage = page;
    this.reload();
  }

  @Watch("searchtext")
  search() {
    this.reload();
  }

  rolechange() {
    this.reload();
  }

  add() {
    this.mode = "new";
    this.user = <User>{ enabled: false, roles: ["ROLE_USER"] };
    this.role = this.user.roles[0];
  }

  edit(user: any) {
    this.mode = "edit";
    this.user = user;
    this.role = user.roles[0];
  }

  save() {
    if (this.mode === "new") {
      AuthService.create(this.user)
        .then((response) => {
          this.mode = "list";
          this.reload();
        })
        .catch((err) => {});
    } else if (this.mode === "edit") {
      this.user.roles = [this.role];
      AuthService.update(this.user)
        .then((response) => {
          this.mode = "list";
          this.reload();
        })
        .catch((err) => {});
    }
  }

  reset() {
    AuthService.reset(this.user)
      .then((response) => {
        this.mode = "list";
        this.reload();
      })
      .catch((err) => {});
  }

  remove(user: any) {
    AuthService.delete(user)
      .then((response) => {
        this.mode = "list";
        this.reload();
      })
      .catch((err) => {});
  }

  cancel(event: any) {
    this.mode = "list";
    this.reload();
  }

  roledisplay(r: string[]) {
    return this.roles
      .filter((s) => r.includes(s.value))
      .map((s) => s.text)
      .join(", ");
  }

  servicedisplay(s: string[]) {
    return s ? s.join(", ") : "";
  }

  state(value: any) {
    if (!value) {
      return false;
    }

    return value.length > 0;
  }

  username(value: any) {
    if (this.mode === "edit") {
      return true;
    }

    if (!value) {
      return false;
    }

    return (
      value.length > 0 &&
      this.users.filter((u: any) => value === u.username).length === 0
    );
  }

  isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  date(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  date2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  reload() {
    this.users = {};

    AuthService.getAll(
      this.searchtext,
      this.currentPage - 1,
      this.pagesize,
      `${this.sortBy},${this.sortDirection}`,
      { role: this.rolesearch }
    )
      .then((response) => {
        this.users = response.data;
      })
      .catch((err) => {});
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);

    AuthService.services().then(
      (response) =>
        (this.services = response.data.map(
          (s: string) => <any>{ value: s, text: s }
        ))
    );

    LocationService.all("name").then((loc) => {
      this.locations = loc.data.content;
    });

    this.reload();
  }
}
